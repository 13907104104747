import React from 'react';
import { compose, withProps, withStateHandlers } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from 'react-google-maps';

const location = { lat: 43.552178, lng: 1.372994 };

const Map = compose(
  withStateHandlers(
    () => ({
      isOpen: true,
    }),
    {
      onToggleOpen: ({ isOpen }) => () => ({
        isOpen: !isOpen,
      }),
    }
  ),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap defaultZoom={15} defaultCenter={location}>
    <Marker
      place={{
        placeId: 'AIzaSyAotHgMFqr9hx8y7_EoNubnV-rnggJp0kE',
        location: location,
      }}
      onClick={props.onToggleOpen}
    >
      {props.isOpen && (
        <InfoWindow onCloseClick={props.onToggleOpen}>
          <div>
            <span style={{ fontWeight: 'bold' }}>INPRINT</span>
            <br />
            31 RUE ARISTIDE BERGES
            <br />
            31270 CUGNAUX
          </div>
        </InfoWindow>
      )}
    </Marker>
  </GoogleMap>
));

export default Map;
