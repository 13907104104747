import React from 'react';
import Map from '../components/Map';
import ContactForm from '../components/ContactForm';
import '../components/layout.scss';
import Layout from '../components/layout';
import * as styles from './contact.module.scss';
import SEO from '../components/seo';

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />

    <section>
      <div className="container">
        <div className="row mb0">
          <div className="col-sm-12 text-center">
            <h1 className="static-h1">Nous contacter</h1>
            <p className="m0">
              Pour toute question ou renseignement complémentaire, n'hésitez pas
              à contacter notre service client.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className={`parallax ${styles.contactForm}`}>
      <div className="large-container">
        <div className="row mb0">
          <div className="col-lg-8">
            <div className="card">
              <h2 className="text-center">Envoyez-nous un message</h2>
              <ContactForm />
            </div>
          </div>
          <div className="col-lg-4 text-center">
            <div className={`card ${styles.coordonnees}`}>
              <h2 className="primary">Nos coordonnées</h2>
              <p>
                Vous pouvez également nous contacter directement par téléphone,
                par mail ou par courrier.
              </p>
              <p>
                Nous sommes disponibles par téléphone
                <br />
                <span className="bold">du lundi au vendredi de 9h à 18h</span> :
              </p>
              <p className={styles.contactTitle}>Service commercial</p>
              <p className="m0">(+33) 05 61 24 65 35</p>
              <p>
                <a href="mailto:contact@oyp.fr">contact@oyp.fr</a>
              </p>
              <p className={styles.contactTitle}>
                Service administratif, ressources humaines <br />
                et comptabilité
              </p>
              <p>(+33) 05 31 61 62 45</p>
              <p className={styles.contactTitle}>Adresse postale</p>
              <p className="m0">
                ZI Le Casque
                <br /> 31 rue Aristide Bergès
                <br /> 31270 Cugnaux - France
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Map
      googleMapURL="https://maps.google.com/maps/api/js?key=AIzaSyA5ck67WRdHmZIWj2aCZius9vS4dm6lhPQ&#038;language=fr&#038;libraries=geometry,places"
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `300px`, width: `100%` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  </Layout>
);

export default ContactPage;
